import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';

// Element
import Banner from '../../elements/banner/banner';
import SEO from '../../elements/seo/seo';

// Markdown 
import ReactMarkdown from "react-markdown";
// import rehypeRaw from "rehype-raw";  // allows to use html tag in markdown


// Picture
import pictureVideo from '../../../images/video/pic2.webp';

// Modal
import Modal from '../../elements/modal/modal';

// Cards 
import Card3 from '../../elements/card/card-3';

// Text
import Text1 from '../../elements/text/text-1';
import Text2 from '../../elements/text/text-2';

// Price
import Price1 from '../../elements/price/price-1';

// Text
//import text3 from '../../../data/text.json';

// Button 
// import Button3 from '../../elements/button/button-3';

export default function YogPlanningIME() {

	// modal Video
	const [openModal, setOpenModal] = useState(false);

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [sec1, setSec1] = useState('')
	const [videoId, setVideoId] = useState('')
	const [sec2, setSec2] = useState('')
	const [card1, setCard1] = useState('')
	const [card2, setCard2] = useState('')
	const [card3, setCard3] = useState('')
	const [sec3, setSec3] = useState('')
	const [sec4, setSec4] = useState('')
	const [buttunDowload, setButtunDowload] = useState('')
	const [buttunContact, setButtunContact] = useState('')
	
	const [price, setPrice] = useState('')
	const [timeLimit, setTimeLimit] = useState('')
	const [engagement, setEngagement] = useState('')
	
	const [cgu, setCgu] = useState('')
	const [copyright, setCopyright] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/yogPlanningIME`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])
				setVideoId(res.data.Composents[2].Corps)
				setSec2(res.data.Composents[3])

				// Cards
				setCard1(res.data.Composents[3].Data[0])
				setCard2(res.data.Composents[3].Data[1])
				setCard3(res.data.Composents[3].Data[2])

				// Price Card 
				setSec3(res.data.Composents[4])
				setPrice(res.data.Composents[4].Data[0])
				setTimeLimit(res.data.Composents[4].Data[1])
				setEngagement(res.data.Composents[4].Data[2])
				setButtunContact(res.data.Composents[4].Data[3])
				setCgu(res.data.Composents[4].Data[4])
				setCopyright(res.data.Composents[5])

				setSec3(res.data.Composents[4])
				setButtunDowload(res.data.Composents[4].Data[0])
				setButtunContact(res.data.Composents[4].Data[1])

				setSec4(res.data.Composents[5]);
			})
			.catch(err => {
			})
	}, []);

	// CONST
	const metaData = {
		title: !meta.TitreMeta ? "YogPlanningIME : gestion de plannings pour les institus spécialisés" : meta.TitreMeta,
		description: !meta.description ? "Logiciel pour le médico-social pour consolider toutes les activités sur un seul outil pour faciliter la gestion pour moins de complications et plus de repères !" : meta.description,
		canonical: !meta.URLCanonical ? "yogPlanningIME" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "YogPlanningIME" : banner.Titre,
		entitled: !banner.Corps ? "YogPlanningIME" : banner.Corps
	}

	// modal ID
	const VideoId = {
		id: !videoId ? "0ks2LG5HaIY" : videoId,
	};

	const cards = [
		{
			icons: "flaticon-laptop",
			title: !card1.Titre ? "Centralisez tous vos documents" : card1.Titre,
			p: !card1.Corps ? "Vous travaillez à plusieurs sur un seul outil. Fini les multiples fichiers Excel et autres supports. YogPlanning vous permet de centraliser tous vos documents à un seul et unique endroit pour un quotidien simplifié." : card1.Corps
		},
		{
			icons: "flaticon-networking",
			title: !card2.Titre ? "Sur un seul support l'ensemble de l'organisation" : card2.Titre,
			p: !card2.Corps ? "Nous vous aidons à consolider sur un seul support l'ensemble de l'organisation de votre structure pour vous permettre de vous concentrer sur l'essentiel de votre métier." : card2.Corps
		},
		{
			icons: "flaticon-time-management",
			title: !card3.Titre ? "Fini la double saisie" : card3.Titre,
			p: !card3.Corps ? "Vous avez été confronté à la complexité de mise en place des différentes activités au sein de votre structure. Finis la double saisie, nous intégrons directement les informations là où vous en avez besoin.." : card3.Corps
		},
	]

	const section3 = {
		title: !sec3.Titre ? "Prix unique" : sec3.Titre,
		pOffer: !sec3.Corps ? "Toutes les fonctionnalités gratuites pendant le premier mois*, puis" : sec3.Corps,
		price: !price.Corps ? "499€" : price.Corps,
		pTime: !timeLimit.Corps ? "par an (HT)" : timeLimit.Corps,
		pBold: !engagement.Corps ? "sans engagement de durée" : engagement.Corps,
		BTNLib1: !sec3.BTNLib ? "télécharger" : sec3.BTNLib,
		BTNUrl1: !buttunDowload.BTNUrl ? "https://install.yogan.pro/YogPlanningIME2/" : buttunDowload.BTNUrl,
		BTNLib2: !buttunContact.BTNLib ? "nous contacter" : buttunContact.BTNLib,
		BTNUrl2: !buttunContact.BTNUrl ? "https://yogan.fr/contact" : buttunContact.BTNUrl,
		copyrightLib: !copyright.BTNLib ? "yogan développement" : copyright.BTNLib,
		copyrightUrl: !copyright.BTNUrl ? "https://yogan.fr/" : copyright.BTNUrl,
		copyright: !sec3.Corps ? "YogplanningIME est un logiciel édité et réalisé par " : sec3.Corps,
		copyrightLib: !sec3.BTNLib ? "yogan développement" : sec3.BTNLib,
		copyrightUrl: !sec3.BTNUrl ? "https://yogan.fr/" : sec3.BTNUrl,
	}

	const text1 = {
		title: !sec1.Titre ? "Gestion de planning" : sec1.Titre,
		p: !sec1.Corps ? "L'outil le plus simple pour gérer tous les plannings de votre institut." : sec1.Corps,
		alignText: "center",
		psize: "19px"
	}

	const text2 = {
		title: !sec2.Titre ? "Conçu avec un institut Médico-Éducatif" : sec2.Titre,
		p: !sec2.Corps ? "Logiciel pour le médico-social pour consolider toutes les activités sur un seul outil pour faciliter la gestion pour moins de complications et plus de repères !" : sec2.Corps,
		alignText: "center",
		psize: "19px"
	}

	const button1 = {
		BTNLib: !buttunContact.BTNLib ? "nous contacter" : buttunContact.BTNLib,
		BTNUrl: !buttunContact.BTNUrl ? "https://yogan.fr/contact" : buttunContact.BTNUrl,
	}

	const button2 = {
		BTNLib: !buttunDowload.BTNLib ? "Télécharger" : buttunDowload.BTNLib,
		BTNUrl: !buttunDowload.BTNUrl ? "https://install.yogan.pro/YogPlanningIME2/" : buttunDowload.BTNUrl,
	}

	const text3 = {
		p: !cgu.Corps ? "*hors demandes spécifiques tel que décrit dans les CGU" : cgu.Corps,
		alignText: "center",
	}
	
	return (
		<>
			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Header />

			{/* if openModal == true then we runing Modal component  */}
			{openModal && <Modal setOpenModal={setOpenModal} VideoId={VideoId.id} />}

			<Banner title={bannerContent.title} hide={true} />

			<div className="section-area section-sp2 bg-white" id="content-area">
				<div className="container" id="presentation">
					<Text1 text={text1} />
					<div className="row section-sp4 content-center">
						<div className="col-lg-8 wow fadeInUp m-md-b30" data-wow-delay="0.4s">
							<div className="video-media-bx border-retouch">
								<img src={pictureVideo} alt="Yogan Développement - YogPlanningIME gestion de planning pour institut spécialisée (médico-social)" />
								<a className="popup-youtube video-zoom" onClick={() => setOpenModal(true)}><i className="fa fa-play"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-area section-sp2 bg-gray" id="conception">
				<div className="container">
					<div className="heading-bx text-center">
						<Text1 text={text2} />
					</div>
					<Card3 cards={cards} />
				</div>
			</div>


{/* <!-- Prix Start --> */}
<div className="section-area section-sp2" id="prix">
				<div className="container">
					<Price1 section3={section3} />
					<i><Text1 text={text3} /></i><br /><br />
					<Text2 text={section3} />
				</div>
			</div>
			{/* <!-- Prix End --> */}
			<Footer />
		</>
	);
}