import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Element
import Sticky from 'react-stickynode';

// Picture
import logo from '../../../images/logo/logo.webp'; /*logo mobile header*/

export default function Header() {

	// Dynamic date
	const [date, setDate] = useState(new Date());
	const options = { year: "numeric" }

	useEffect(() => {
		// refresh the data every year
		const intervalId = setInterval(() => setDate(new Date()), 31536000);

		return function () {
			clearInterval(intervalId);
		};
	}, [date]); // Only re-run the effect if date changes

	useEffect(() => {

		// Search Form Popup
		//var searchBtn = document.getElementById("quik-search-btn")
		var searchForm = document.querySelector(".nav-search-bar")
		var closeBtn = document.getElementById("search-remove")

		// searchBtn.addEventListener('click',function(){
		//     searchForm.classList.add("show")
		// })
		closeBtn.addEventListener('click', function () {
			searchForm.classList.remove("show")
		})

		var menuIcon = document.querySelector(".menuicon")
		var menuLinks = document.querySelector(".menu-links")
		var menuClose = document.getElementById("menuClose")

		menuIcon.addEventListener('click', function () {
			menuLinks.classList.add("show")
		})
		menuClose.addEventListener('click', function () {
			menuLinks.classList.remove("show")
		})

		// Mobile Submenu Open Close Function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}

		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));

			if (active) {
				current.classList.remove('open')
				console.log("active")
			} else {
				current.classList.add('open');
				console.log("close")
			}
		}

	}, [])


	return (
		<>
			{/* <!-- Header Top ==== --> */}
			<header className="header rs-nav header-transparent">
				<Sticky enabled={true} className="sticky-header navbar-expand-lg">
					<div className="menu-bar clearfix">
						<div className="container-fluid clearfix">
							{/* <!-- Header Logo ==== --> */}
							{/* <div className="menu-logo">
								<Link to="/"><img src={logoHorizontale} alt="Yogan Développement - logo d'entreprise" /></Link>
							</div> */}
							{/* <!-- Mobile Nav Button ==== --> */}
							<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
								<span></span>
								<span></span>
								<span></span>
							</button>
							{/* <!-- Author Nav ==== --> */}
							<div className="secondary-menu">
								<div className="secondary-inner">
									<ul>
										{/* <li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"><i className="fa fa-search"></i></button></li> */}
										{/* <li className="search-btn"><a href="/mon-compte"><button  type="button" className="btn-link"><i className="fa fa-user"></i></button></a></li>
											<li className="search-btn"><a href="/wishlist"><button  type="button" className="btn-link"><i className="fa fa-heart"></i></button></a></li>
											<li className="search-btn"><a href="/panier"><button  type="button" className="btn-link"><i className="fa fa-shopping-basket"></i></button></a></li> */}
										{/* <li className="search-btn"><button  type="button" className="btn-link"><i className="fa fa-sign-in"></i></button></li> */}
									</ul>
								</div>
							</div>
							{/* <!-- Navigation Menu ==== --> */}
							<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
								<div className="menu-logo">
									<Link to="/"><img src={logo} alt="Yogan Développement - logo d'entreprise" /></Link>
								</div>
								<ul className="nav navbar-nav">
									<li>
										<a href="#presentation">Présentation </a>
									</li>
									<li>
										<a href="#conception">Conception </a>
									</li>
									<li>
										<a href="#contact">Contact </a>
									</li>
									<li className="hide">
										<a href="tel:0465230000">04 65 23 00 00</a>
									</li>
								</ul>
								<div className="navbar-footer">
									<ul className="nav-social-link">
										<li><a href="tel:0465230000" className="btn greenphone" rel="noreferrer"><i className="fa fa-phone" alt="Appeler Yogan Développement"></i></a></li>
										<li><a href="https://www.facebook.com/yogansoftware/" className="btn bluefacebook" rel="noreferrer"><i className="fa fa-facebook" alt="Yogan Développement Facebook"></i></a></li>
										<li><a href="https://mobile.twitter.com/yogansoftware" className="btn bluetwitter" rel="noreferrer"><i className="fa fa-twitter" alt="Yogan Développement Twitter"></i></a></li>
										<li><a href="https://es.linkedin.com/company/yogan" className="btn bluelinkedin" rel="noreferrer"><i className="fa fa-linkedin" alt="Yogan Développement Linkedin"></i></a></li>
										<li><a href="https://www.youtube.com/channel/UC2qrLXlbOajb6NRCdhBZlxg/videos?app=desktop&cbrd=1" className="btn redyoutube" rel="noreferrer"><i className="fa fa-youtube-play" alt="Yogan Développement Youtube"></i></a></li>
									</ul>
									<p className="copyright-text text-black text-uppercase fw-bold">© {date.toLocaleDateString("fr-FR", options)} YogShow</p>
								</div>
								<div className="menu-close" id="menuClose">
									<i className="ti-close"></i>
								</div>
							</div>
							{/* <!-- Navigation Menu END ==== --> */}
						</div>
					</div>
				</Sticky>
				{/* <!-- Search Box ==== --> */}
				<div className="nav-search-bar">
					<form action="#">
						<input name="search" defaultValue="" type="text" className="form-control" placeholder="Type to search" />
						<span><i className="ti-search"></i></span>
					</form>
					<span id="search-remove"><i className="ti-close"></i></span>
				</div>
			</header>
			{/* <!-- Header Top END ==== --> */}
		</>
	);
}