import React from "react";
import { Link } from "react-router-dom";

// Layout
import Footer from "../../layout/footer/footer";

// Element
import SEO from '../../elements/seo/seo';
import Banner from '../../elements/banner/banner';

export default function Copyright() {
    return (
        <>
            {/* Dynamic Meta Tags */}
            <SEO
                title={"Règles relatives au droit d'auteur"}
                description="Les textes et éléments constitutifs du site sont protégés contre la reproduction et la contrefaçon par les lois en vigueur relatives à la protection intellectuelle."
                canonical="copyright"
            />

            <Banner title="Copyright" entitled="Copyright" navTitle="Accueil" navLink="/" />
            
            <div className="page-content bg-white">
                <div className="content-block" id="content-area">
                    <div className="section-area section-sp1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 mb-5">
                                    <h2 className="text-capitalize text-center">Règles Relatives Au Droit D'auteur ©</h2>
                                    <blockquote>
                                        <span>
                                            Nous tenons à rappeler que les textes et créations présentes sur le présent site sont l’unique propriété de <Link to="/">Yogan Développement</Link> et que toute reproduction,
                                            même partielle, est strictement interdite sauf accord préalable et écrit.
                                        </span>
                                    </blockquote>
                                    <p>
                                        En plus d'enfreindre les droits de propriété intellectuelle,
                                        les moteurs de recherche comme Google pénalisent fortement le contenu dupliqué qui consiste en une copie du même texte plusieurs fois sur différents sites.
                                    </p>
                                    <p>
                                        Cette mauvaise pratique est néfaste pour le référencement des sites impliqués est peut-être lourde de conséquences pour les deux parties concernées.
                                    </p>
                                    <p>
                                        Pour en savoir plus sur les règles relatives au Copyright nous vous conseillons de vous informer et
                                        de prendre connaissance de vos responsabilités sur les <a href="https://www.inpi.fr/comprendre-la-propriete-intellectuelle/les-autres-modes-de-protection/le-droit-dauteur" target="_blank" rel="noreferrer">sites officiels en ligne</a>.
                                        Toute l'équipe de <Link to="/" className="link-hover-primary">Yogan Développement</Link> vous remercie par avance pour votre considération.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}