import React from "react";

export default function Price1(props) {
    return (
        <>
            <div className="row content-center">
                <div className="col-lg-8">
                    <div className="feature-container left feature-bx1">
                        <div className="icon-content"><br />
                            <h2 className="ttr-tilte text-center">{props.section3.title}</h2>
                            <p className="text-center">{props.section3.pOffer}</p>
                            <h3 className="title-head m-b0 text-center">{props.section3.price}</h3>
                            <p className="title-head m-b0 text-center">{props.section3.pTime}</p>
                            <p className="text-center fst-italic fw-bold">{props.section3.pBold}</p>
                            <div className="col-lg-12 text-center">
                                <div className="button-space margin">
                                    <a href={props.section3.BTNUrl1} id="responsive-two-button-side1" className="btn button-md radius-xl space" target="_blank" rel="noreferrer">{props.section3.BTNLib1}</a>
                                    <a href={props.section3.BTNUrl2} id="responsive-two-button-side2" className="btn button-md radius-xl space" target="_blank" rel="noreferrer">{props.section3.BTNLib2}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}